<template>
  <div id="printMe">
    <div
      v-for="(eVoucher, index) in eVoucherInfoList"
      :key="eVoucher + index">
      <layout-print-voucher
        :customer-name="concatFullname(eVoucher.info.customer.last_name, eVoucher.info.customer.first_name)"
        :customer-email="eVoucher.info.customer.email"
        :voucher-name="eVoucher.issuanceInfo.name"
        :voucher-price="eVoucher.info.value"
        :voucher-type="eVoucher.issuanceInfo.type"
        :voucher-serial="eVoucher.info.serial"
        :activation-code="eVoucher.info.activation_code"
        :supplier-name="eVoucher.issuanceInfo.owner.name"
        :supplier-hotline="eVoucher.issuanceInfo.owner.phone"
        :supplier-website="eVoucher.issuanceInfo.owner.website"
        :expired-date="formatDate(eVoucher.info.expired_date)"
        :voucher-description="eVoucher.issuanceInfo.description"
        :voucher-term-of-conditions="eVoucher.issuanceInfo.conditions_of_use"
        :voucher-details="eVoucher.issuanceInfo.details"
        :logo-supplier-url="eVoucher.issuanceInfo.owner.image_url"
        :logo-agent-url="eVoucher.distributerInfo.image_url"
        :background-url="eVoucher.printTemplateInfo.backgroundUrl"
        :second-background-url="eVoucher.printTemplateInfo.secondBackgroundUrl"
        :is-hide-price="eVoucher.issuanceInfo.is_hide_price"
      />
    </div>
    <div
      class="voucher-print-button"
      style="position: fixed; bottom: 0;">
      <v-btn
        class="btn"
        color="warning"
        dark
        @click="onPrintPage"
      >
        <v-icon class="mr-2">mdi-printer</v-icon>
        <span style="text-transform: none;">{{
          $t('printVoucher.print')
        }}</span>
      </v-btn>
    </div>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="confirmPrintEVoucher"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import dateUtils from 'utils/dateUtils'
import VoucherPrintType from 'enum/voucherPrintType'
import PrintTemplateItemKind from 'enum/printTemplateItemKind'
import ConfirmModal from 'Components/ConfirmModal'
import ToastType from 'enum/toastType'
import QrcodeVue from 'qrcode.vue'
import LayoutPrintVoucher from 'Components/LayoutPrintVoucher.vue'
export default {
  components: {
    'confirmModal': ConfirmModal,
    LayoutPrintVoucher,
    QrcodeVue
  },
  data: () => ({
    eVoucherInfoList: [],
    VoucherPrintType: VoucherPrintType,
    isLoading: false,
    confirmModalTitle: ''
  }),
  computed: {
    ...mapGetters([
      'VOUCHER_PRINT_DATA',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    VOUCHER_PRINT_DATA: function () {
      let data = this.VOUCHER_PRINT_DATA
      this.eVoucherInfoList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let printTemplateInfo = {
          backgroundUrl: null,
          secondBackgroundUrl: null
        }
        data[i].template_info.forEach(e => {
          if (e.kind === PrintTemplateItemKind.BACKGROUND_IMAGE) {
            printTemplateInfo.backgroundUrl = e.value
          } else if (e.kind === PrintTemplateItemKind.BACKGROUND_2ND_IMAGE) {
            printTemplateInfo.secondBackgroundUrl = e.value
          }
        })
        this.eVoucherInfoList.push({
          info: data[i].voucher_info,
          issuanceInfo: data[i].workpiece,
          distributerInfo: data[i].distributer,
          printTemplateInfo: printTemplateInfo
        })
      }
      this.isLoading = false
    }
  },
  created () {
    let type = this.$route.query.type
    let voucherIds = this.$route.query.voucherIds
    let params = this.$route.query.params
    if (type === VoucherPrintType.AUTOMATIC) {
      params = JSON.parse(params)
      params['page'] = ''
    }
    let filter = {
      params: {
        voucherIds: voucherIds,
        printType: type,
        ...params
      }
    }
    this.isLoading = true
    this.GET_VOUCHERS_PRINT(filter).then(
      function () {
      }
    ).catch(
      function () {
        this.isLoading = false
      }.bind(this)
    )
  },
  beforeCreate: function () {
    document.body.className = 'print'
  },
  methods: {
    /**
     * Confirm print e voucher
     */
    confirmPrintEVoucher: function () {
      let voucherIds = this.$route.query.voucherIds
      let filter = {
        voucherIds: voucherIds
      }
      this.PRINT_VOUCHER(filter).then(
        function () {
          // window.print()
          this.$htmlToPaper('printMe')
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              message: this.$t(errorText),
              styleType: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              message: this.$t('login.somethingWentWrong'),
              styleType: ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    /**
     * Print page
     */
    onPrintPage: function () {
      this.confirmModalTitle = this.$t('printVoucher.youSurePrintVoucher')
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Format date
     */
    formatDate: function (date) {
      return dateUtils.formatBeautyDate(date, null)
    },
    concatFullname: function (lastname, firstname) {
      return functionUtils.concatFullname(lastname, firstname)
    },
    /**
     * Close page
     */
    onClosePage: function () {
      window.close()
    },
    ...mapActions([
      'GET_VOUCHERS_PRINT',
      'PRINT_VOUCHER',
      'ON_SHOW_TOAST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.theme--dark.v-sheet {
    padding: 5px;
}
</style>
